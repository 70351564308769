<template>
  <div class="e-product e-product--purpose">
    <div class="e-product__image">
      <img :src="require(`@/assets/images/${purpose.img}`)" :alt="purpose.name">
    </div>
    <!-- / e-product__image -->

    <div class="e-product__bottom">
      <input class="e-product__checkbox" type="radio" @change="updatePurpose()" name="purpose" :checked="selectedPurpose.id == purpose.id">
      <div class="e-product__checked"></div>
      <p class="e-product__name">{{ purpose.name }}</p>
    </div>
    <!-- / e-product__bottom -->

    <transition name="fade">
      <div class="e-product__options" v-if="selectedPurpose.id == purpose.id && options">
        <div class="e-product__option" v-for="(option, index) in options" :key="index">
          <input class="e-product__checkbox" type="radio" name="purposeOption" @change="updatePurposeOption(option)" :checked="selectedPurpose.option == option && ! selectedPurpose.custom">
          <div class="e-product__checked"></div>
          {{ option }}
        </div>
        <!-- / e-product__option -->

        <div class="e-product__option">
          <input class="e-product__checkbox" type="radio" name="purposeOption" @click="updatePurposeOption(other, true)" :checked="selectedPurpose.option == other && selectedPurpose.custom">
          <div class="e-product__checked"></div>
          <input class="e-product__input" type="text" placeholder="Inne" ref="other" v-model="other" @input="updatePurposeOption(other, true)">
        </div>
        <!-- / e-product__option -->

      </div>
      <!-- / e-product__options -->

    </transition>

  </div>
  <!-- / e-product -->

</template>

<script>
export default {
  name: "Purpose",
  props: {
    purpose: {
      type: Object
    }
  },
  components: {
  },
  data() {
    return {
      selected: false,
      other: '',
    }
  },
  mounted() {
    if (this.selectedPurpose.custom) {
      this.other = this.selectedPurpose.option;
    }
  },
  methods: {
    updatePurpose() {
      this.$store.commit('updatePurpose', { id: this.purpose.id });
    },
    updatePurposeOption(option, focus = false) {
      if (option.length > 64 && focus) {
        option = option.substring(0, 64);
        this.other = option;
      }

      if (focus) {
        this.$refs.other.focus();
      }

      this.$store.commit('updatePurposeOption', { id: this.purpose.id, option, custom: focus });
    }
  },
  computed: {
    options() {
      return this.purpose.options;
    },
    selectedPurpose() {
      return this.$store.state.selectedPurpose;
    },
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/e-product.scss';

  .fade-enter-active, .fade-leave-active {
    @include transition();
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-10px);
  }
</style>

