<template>
  <main class="step-page2">
    <Heading :title="'Zastosowanie'" />
    <section class="step2 step step--second">
      <div class="step__products">
        <Purpose data-aos="fade-in" :data-aos-delay="index*250" v-for="(purpose, index) in purposes" :key="purpose.id" :purpose="purpose" />
      </div>
      <Bottom>
        <Button :path="'step1'" :title="'Wróć'" :classes="'e-button--secondary'" />
        <Button :path="'step3'" :title="'Dalej'" :classes="btnClass" />
      </Bottom>
    </section>
  </main>
</template>

<script>
import Purpose from "@/components/Purpose.vue";
import Bottom from "@/components/Bottom.vue";
import Button from "@/components/Button.vue";
import Heading from "@/components/Heading.vue";

export default {
  name: "Step2",
  components: {
    Purpose,
    Bottom,
    Button,
    Heading
  },
  computed: {
    purposes() {
      return this.$store.state.purposes;
    },
    btnClass() {
      if (Object.keys(this.$store.state.selectedPurpose).length) {
        if ('option' in this.$store.state.selectedPurpose) {
          return this.$store.state.selectedPurpose.option ? 'e-button--primary' : 'e-button--disabled';
        } else {
          return 'e-button--primary';
        }
      } 

      return 'e-button--disabled'
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.checkRoute(to, from)) {
      next();
    }

    if (! Object.keys(this.$store.state.selectedPurpose).length) {
      return;
    } else {
      if ('option' in this.$store.state.selectedPurpose) {
        if (! this.$store.state.selectedPurpose.option) {
          return;
        }
      }
    }

    next();
  }
};
</script>

<style scoped lang="scss">
.step2 {}
</style>

